@import '../../../styles.scss';


.scilent-music-page {
	display: flex;
	flex-direction: column;
    height: auto;
    overflow-y:scroll;
    overflow-x: hidden;
    background-color: $scilentBlack;
	gap: var(--space-m);
    padding: var(--space-m);

	h1 {
        text-align: center;
		letter-spacing: 0.25rem;
		font-weight: 200;
	}

	h2 {
		line-height: 1.8rem;
		letter-spacing: 0.5rem;

		// font-size: var(--font-size-md);
		// letter-spacing: 0.07rem;
		// color: $dark;
	}

	h3 {
		text-align: center;
		font-weight: 200;
		letter-spacing: 0.25rem;
		font-size: var(--font-size-sm);
	}
	
	h4 {
		font-weight: 200;
		text-transform: none;
	}

	p {
		font-size: var(--font-size-sm);
		letter-spacing: 0.1rem;
		text-transform: none;
	}

	ul {
		list-style: none;
		padding: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;

		li {
			flex: 1;
			font-size: var(--font-size-sm);
			letter-spacing: 0.04rem;
			color: $light;
			margin: var(--space-xs) 0;

			.feature-li  {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	.features {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: var(--space-m);
		max-height: 100svh;

		.feature {
			flex: 1;

			.feature-heading {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: var(--space-2xs);
				justify-content: center;
			}

			img {
				width: 100%;
				border-radius: 12px;
			}
			
			p {
				text-align: left;
			}
		}
	}

	ul.feature-details {
		display: flex;
		flex-direction: column;

	}

	hr {
		width: 100%;
	}

	* {
		color: $light;
	}

	img {
		width: 100%;
		border-radius: 12px;
	}
}