@import 'node_modules/nord/src/sass/nord.scss';
@import '../../styles.scss';

@keyframes hide {
	from {
		margin-top: 0%;
		width: 100%;
	}

	to {
		margin-top: -100%;
		width: 300%;
	}
}

@keyframes show {
	from {
		margin-top: -100%;
		width: 300%;
	}

	to {
		margin-top: 0%;
		width: 100%;
	}
}

.nav-bar {
	position: sticky;
	display: flex;
	flex-direction: row;
	padding: var(--space-s);
	margin: 0 auto;
	width: 85%;
	align-items: center;
	justify-content: space-between;
	max-height: 6vmin;

	a {
		transition: all 0.25s ease;
		color: $dark;
		pointer-events: all;

		&:hover,
		&:focus {
			font-family: 'TTH-Bold', monospace;
			font-size: 1.2rem;
			letter-spacing: 0.2rem;
			color: $dark;

			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
			-webkit-text-stroke: 1px $dark;
			&.active {
				border: none;
			}
		}

		&.active {
			font-family: 'TTH-Bold', monospace;
			border-bottom: 1px solid $accent;
		}
	}
	.logo {
		display: flex;
		flex-direction: column;
		pointer-events: all;

		a {
			font-family: 'Montserrat', serif;
			text-transform: lowercase;
			font-weight: 900;
			font-size: var(--font-size-lg);
			color: $dark;

			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
			-webkit-text-stroke: 1px $dark;
			transition: all 0.25s ease;

			&:hover {
				-webkit-text-fill-color: initial;
				-webkit-background-clip: initial;
				-webkit-text-stroke: initial;
				color: $dark;
			}
		}
	}
}
