// @import 'node_modules/nord/src/sass/nord.scss';

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'),
		url(./fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format('truetype');
	/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
	font-family: 'TTH-Black';
	src: local('TTH-Black'),
		url(./fonts/TT-Hoves/TT-Hoves-Black.otf) format('opentype');
	/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
	font-family: 'TTH-Bold';
	src: local('TTH-Bold'),
		url(./fonts/TT-Hoves/TT-Hoves-Bold.otf) format('opentype');
	/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
	font-family: 'TTH-SemiBold';
	src: local('TTH-SemiBold'),
		url(./fonts/TT-Hoves/TT-Hoves-SemiBold.otf) format('opentype');
	/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
	font-family: 'TTH-Regular';
	src: local('TTH-Regular'),
		url(./fonts/TT-Hoves/TT-Hoves-Regular.otf) format('opentype');
	/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
	font-family: 'TTH-Thin';
	src: local('TTH-Thin'),
		url(./fonts/TT-Hoves/TT-Hoves-Thin.otf) format('opentype');
	/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
	font-family: 'TTH-Light';
	src: local('TTH-Light'),
		url(./fonts/TT-Hoves/TT-Hoves-Light.otf) format('opentype');
	/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
@font-face {
	font-family: 'TTH-ExtraLight';
	src: local('TTH-ExtraLight'),
		url(./fonts/TT-Hoves/TT-Hoves-ExtraLight.otf) format('opentype');
	/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */
}
