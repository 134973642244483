@import 'node_modules/nord/src/sass/nord.scss';
@import '../../styles.scss';

.contact-content {
	display: flex;
	flex: 1;
	justify-content: space-around;
	align-items: center;
}

.contact-heading-text {
	margin: 0;
	flex: 1;
}

.contact-links {
	display: flex;
	align-items: center;

	.icon-grid {
		display: flex;
		gap: var(--space-l);
		@media only screen and (max-width: 1200px) {
			flex-direction: column;
		}

		.icon-grid-link,
		.icon {
			max-height: 50px;
			width: 50px;
			@media only screen and (max-width: 990px) {
				max-height: 35px;
				width: 35px;
			}
		}
		.icon {
			// convert hex to css filter https://codepen.io/sosuke/pen/Pjoqqp
			filter: invert(11%) sepia(5%) saturate(6405%) hue-rotate(198deg)
				brightness(90%) contrast(79%);
			opacity: 0.7;
			transition: all 0.25s ease;

			&:hover {
				opacity: 1;
				transform: scale(1.2);
			}
		}
	}
}
