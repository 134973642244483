@import '../../../styles.scss';
@import url('https://fonts.googleapis.com/css2?family=Doto:wght,ROND@100..900,0..100&family=Major+Mono+Display&family=Space+Mono:wght@400;700&display=swap');

.doto-100 { // <uniquifier>: Use a unique and descriptive class name
  font-family: "Doto", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100; // <weight>: Use a value from 100 to 900
  font-style: normal;
  font-variation-settings:
    "ROND" 0; // <roundness>: Use a value from 0 to 100
}

.major-mono {
  font-family: "Major Mono Display", monospace;
  font-weight: 400;
  font-style: normal;
}

.space-mono-regular {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.space-mono-bold {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: normal;
}


.scilent-ui-page {
	display: flex;
	flex-direction: column;
    height: auto;
    overflow-y:scroll;
    overflow-x: hidden;
    background-color: $scilentBlack;
	gap: var(--space-m);
    padding: var(--space-m);
    position: relative; // Added for positioning context

	h1 {
		letter-spacing: 0.25rem;
		font-weight: 200;
	}

	h2 {
		line-height: 1.8rem;
		letter-spacing: 0.5rem;
	}

	h3 {
		font-weight: 200;
		letter-spacing: 0.25rem;
		font-size: var(--font-size-sm);
	}
	
	h4 {
		font-weight: 200;
		text-transform: none;
	}

	p {
		font-size: var(--font-size-sm);
		letter-spacing: 0.1rem;
		text-transform: none;
	}

	ul {
		list-style: none;
		padding: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-content: space-evenly;

		li {
			flex: 1;
			font-size: var(--font-size-sm);
			letter-spacing: 0.04rem;
			color: $light;
			margin: var(--space-xs) 0;

			.feature-li  {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	.features {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: var(--space-m);
		max-height: 100svh;
        flex-wrap: wrap;

		.feature {
			flex: 1;
            min-width: 300px;

			.feature-heading {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: var(--space-2xs);
				justify-content: center;
			}

			img {
				width: 100%;
				border-radius: 12px;
				cursor: pointer;
				transition: transform 0.3s ease, box-shadow 0.3s ease;
				will-change: transform, box-shadow;
				
				&:hover {
					transform: scale(1.02);
					box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
				}
			}
			
			p {
				text-align: left;
			}
		}
	}

	ul.feature-details {
		display: flex;
		flex-direction: column;
	}

    
	* {
        color: $light;
	}
    
	img {
        width: 100%;
		border-radius: 12px;
	}
	
	// Fullscreen overlay
	.fullscreen-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.9);
		z-index: 1000;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 0;
		animation: fadeIn 0.3s ease forwards;
		cursor: pointer;
		
		img {
			max-width: 90%;
			max-height: 90%;
			width: auto;
			height: auto;
			object-fit: contain;
			border-radius: 12px;
			transform: scale(0.9);
			animation: scaleIn 0.3s ease forwards;
			box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
			cursor: pointer;
		}
	}
}

hr {
    width: 100%;
    height: 0.5px;
    border-width: 0.5px;
    border-color: $light;
}

// Animations
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes scaleIn {
	from {
		transform: scale(0.9);
	}
	to {
		transform: scale(1);
	}
}