@import '../../styles.scss';

.portfolio-page {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100vw;
	overflow-y: auto;
	align-items: center;

	&-heading {
		align-self: flex-start;
		margin: var(--space-m);
	}
	&-overview {
		align-self: flex-end;
		margin: var(--space-m);
		color: $dark;
	}
	&-desc {
		align-self: center;
		margin: var(--space-m);
		max-width: 80%;
		text-align: center;
		text-wrap: pretty;
	}
	&-hero-img {
		width: 100vw;
	}
	&-feature {
		width: 80vw;
		&-desc {
			align-self: start;
			text-align: start;
		}
		&-img {
			width: 100%;
			margin: 0 auto;
			border-radius: 32px;
		}
	}
	&-footer {
		width: 70%;
		margin-top: var(--space-m);
		padding: var(--space-s);
		border-top: 1px solid $light;

		&-content {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: var(--space-s);
		}

		&-image {
			max-height: 120px;
			border-radius: 6px;
		}
	}
}
