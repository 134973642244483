@import 'node_modules/nord/src/sass/nord.scss';
@import '../../styles.scss';

.resume-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: var(--space-l);
	margin: 0 var(--space-l);
	overflow-y: auto;
	overflow-x: hidden;
	background-color: rgba($color: $light, $alpha: 0.6);
	color: $dark;
	border-radius: 0.5rem;

	.resume-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		&-title {
			h2 {
				margin-bottom: var(--space-3xs);
			}
			h4 {
				color: $med;
			}
		}
		&-icons {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
			.icon {
				height: 32px;
				// convert hex to css filter https://codepen.io/sosuke/pen/Pjoqqp
				filter: invert(11%) sepia(5%) saturate(6405%) hue-rotate(198deg)
					brightness(90%) contrast(79%);
				opacity: 0.7;
				transition: all 0.25s ease;

				&:hover {
					opacity: 1;
					transform: scale(1.2);
				}
			}
		}
	}

	.resume-content {
		display: flex;
		flex: 1;
		gap: var(--space-m);

		.resume-content-section {
			padding: var(--space-s) 0;
		}

		&-left {
			min-width: 60%;
		}
		.resume-content-section:not(.resume-content-section:last-of-type) {
			border-bottom: 1px solid $light;
		}
	}

	.resume-content-entry {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: var(--space-s) 0;

		p {
			margin: 0;
		}
		&-long {
			display: flex;
			gap: var(--space-3xs);
			flex-wrap: wrap;
		}
	}
	h5 {
		margin-bottom: var(--space-3xs);
		color: $med;
	}
	h4 {
		margin-bottom: var(--space-xs);
	}

	ul {
		padding: 0;
		li,
		span {
			list-style: none;
			line-height: 1.7;
		}
	}
}
