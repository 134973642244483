@import 'node_modules/nord/src/sass/nord.scss';
@import '../../styles.scss';

.container {
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	padding: var(--space-xs) 0;

	hr {
		width: 90%;
	}

	.portfolio-item {
		margin: 0 auto;
		width: 70%;

		&-img {
			width: 100%;
			border-radius: 12px;
			opacity: 0.7;
			scale: 1;
			filter: grayscale(1);
			margin: var(--space-s) 0;
			transition: all 0.2s ease-in-out;

			&:hover {
				opacity: 1;
				scale: 1.05;
				box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
					rgba(0, 0, 0, 0.22) 0px 15px 12px;
				filter: grayscale(0);
				border: 0.75px solid $scilentBlack;
			}
		}

		&-text {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			&-title {
				margin-bottom: var(--space-xs);
			}
			.portfolio-item-desc {
				max-width: 50%;
				margin-bottom: var(--space-xs);
				text-align: right;
				line-height: 1;
				color: $dark;
				opacity: 0.7;
			}
		}
	}
}
