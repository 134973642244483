// @import 'node_modules/nord/src/sass/nord.scss';
@import '../../styles.scss';

.app-content {
	flex: 1;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;

	@media only screen and (max-width: 600px) {
		justify-content: center;
		align-items: center;
	}

	@media only screen and (max-width: 992px) {
		justify-content: center;
	}

	@media only screen and (min-width: 1200px) {
		flex-wrap: wrap;
	}
}

.app-header {
	display: flex;
	flex-direction: column-reverse;
	width: 100vw;

	.svg-container {
		.svg-text {
			font-size: var(--font-size-sm);
			text-transform: uppercase;
			font-family: 'TTH-SemiBold';
			fill: $light;
			.landmark {
				font-family: 'TTH-ExtraLight';
				fill: $med;
			}
		}
	}

	.hero-typed-text {
		margin: var(--space-2xs) var(--space-m);
		span {
			font-family: 'TTH-ExtraLight';
			font-size: var(--font-size-md);
			letter-spacing: var(--space-3xs);
			text-transform: uppercase;
			color: $dark;
		}
	}
}
